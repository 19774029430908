var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isAdmin && _vm.filieres.length > 1)?_c('v-card',{staticClass:"pa-10 mb-10",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Filtres")]),_c('v-card-text',{staticClass:"pa-0"},[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"small-chips":"","multiple":"","outlined":"","dense":"","label":"Filières","items":_vm.filieres},model:{value:(_vm.selectedFilieres),callback:function ($$v) {_vm.selectedFilieres=$$v},expression:"selectedFilieres"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.resetFilters}},[_vm._v("Réinitialiser les filtres")])],1)],1):_vm._e(),_c('v-card',{staticClass:"pa-10",attrs:{"outlined":""}},[_c('v-card-title',[_c('v-text-field',{staticClass:"mb-6",attrs:{"append-icon":"mdi-magnify","label":"Rechercher","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.absences,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"locale":"fr-CH","search":_vm.search,"footer-props":{
        itemsPerPageOptions: [10, 30, 50],
        itemsPerPageText: 'Élements par page',
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
      var items = ref.items;
return [_c('tbody',{staticClass:"text-left"},_vm._l((items),function(item){return _c('tr',{key:item._id,on:{"click":function($event){return _vm.$router.push(("/absences/" + (item._id)))}}},[_c('td',[_vm._v(_vm._s(item.student.lastname))]),_c('td',[_vm._v(_vm._s(item.student.firstname))]),_c('td',[_vm._v(_vm._s(item.filiere))]),_c('td',[_vm._v(_vm._s(item.year))]),_c('td',[_vm._v(_vm._s(_vm.format(_vm.parseISO(item.start), "dd.MM.yyyy HH:mm")))]),_c('td',[_vm._v(_vm._s(_vm.format(_vm.parseISO(item.end), "dd.MM.yyyy HH:mm")))]),_c('td',[(item.status === 'pending')?_c('v-chip',{attrs:{"color":"grey","text-color":"white","small":""}},[_vm._v("À traiter")]):_vm._e(),(item.status === 'accepted')?_c('v-chip',{attrs:{"small":"","color":"green lighten-1","text-color":"white"}},[_vm._v("Justification acceptée")]):_vm._e(),(item.status === 'rejected')?_c('v-chip',{attrs:{"small":"","color":"red lighten-1","text-color":"white"}},[_vm._v("Justification refusée")]):_vm._e(),(item.status === 'processing')?_c('v-chip',{attrs:{"small":"","color":"orange lighten-1","text-color":"white"}},[_vm._v("En cours de traitement")]):_vm._e(),(item.status === 'cancelled')?_c('v-chip',{attrs:{"small":"","color":"grey","text-color":"white"}},[_vm._v("Annulé")]):_vm._e()],1),_c('td',[_vm._v(" "+_vm._s(_vm.format(_vm.parseISO(item.createdAt), "dd.MM.yyyy HH:mm"))+" ")]),_c('td',{staticClass:"text-center"},[(item.upload)?_c('div',[_c('v-icon',[_vm._v("mdi-paperclip")])],1):_vm._e()])])}),0)]}},{key:"footer.page-text",fn:function(ref){
      var pageStart = ref.pageStart;
      var pageStop = ref.pageStop;
      var itemsLength = ref.itemsLength;
return [_vm._v(_vm._s(pageStart)+"-"+_vm._s(pageStop)+" de "+_vm._s(itemsLength))]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }