<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field
          outlined
          dense
          placeholder="Rechercher..."
          v-model="search"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mb-0">
      <v-col>
        <student-list-admin :search="search"></student-list-admin>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StudentListAdmin from "@/components/StudentListAdmin";

export default {
  name: "App",
  components: { StudentListAdmin },
  data: () => ({
    search: "",
  }),
};
</script>

<style>
.v-speed-dial {
  position: absolute;
}
.v-btn--floating {
  position: relative;
}
</style>