<template>
  <v-container>
    <v-row class="mb-0">
      <v-col>
        <absence-details :openSnackbar="openSnackbar" />
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" right top>
          {{ snackbar.text }}
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar.visible = false">
              Fermer
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AbsenceDetails from "@/components/AbsenceDetails";
export default {
  name: "App",
  components: { AbsenceDetails },
  data: () => ({ snackbar: {} }),
  methods: {
    openSnackbar(config) {
      this.snackbar = config;
    },
  },
};
</script>

<style>
.v-speed-dial {
  position: absolute;
}
.v-btn--floating {
  position: relative;
}
</style>