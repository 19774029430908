<template>
  <v-app>
    <v-app-bar app flat color="grey lighten-4">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <user-profile />
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer" overlay-opacity="0">
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar tile>
            <img
              src="https://storage.googleapis.com/visual-identity/logo/2020-slim.svg"
              alt="Logo HEIG-VD"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>ABSENCES</v-list-item-title>
            <v-list-item-subtitle>Interface de gestion</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item>
          <v-list-item-content>
            <v-select
              outlined
              dense
              block
              label="Année académique"
              :items="years"
              v-model="year"
              class="mt-10"
            ></v-select>
          </v-list-item-content>
        </v-list-item>
        <v-subheader>DEMANDES</v-subheader>
        <v-list-item link to="/pending-absences">
          <v-list-item-icon>
            <v-icon>mdi-inbox-full-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>À traiter</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/processing-absences">
          <v-list-item-icon>
            <v-icon>mdi-progress-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>En cours de traitement</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/history">
          <v-list-item-icon>
            <v-icon>mdi-history</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Traité</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/students">
          <v-list-item-icon>
            <v-icon>mdi-account-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Étudiants</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-subheader>ACTIONS</v-subheader>
        <v-list-item @click="downloadReport">
          <v-list-item-icon>
            <v-icon>mdi-cloud-download-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Exporter les données</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main class="mt-10">
      <router-view :year="year" :years="years" />
    </v-main>
  </v-app>
</template>

<script>
import UserProfile from "@/components/UserProfile";
import axios from "axios";
import fileDownload from "js-file-download";

export default {
  name: "App",
  components: { UserProfile },
  data: () => ({
    fab: false,
    drawer: null,
    years: ["2021-2022", "2022-2023", "2023-2024", "2024-2025"],
    year: "2024-2025",
  }),
  methods: {
    async downloadReport() {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/absences/export`,
        responseType: "arraybuffer",
      });
      fileDownload(data, "requests.xlsx");
    },
  },
};
</script>

<style>
.separator {
  display: inline-block;
}
</style>