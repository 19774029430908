<template>
  <div>
    <v-expansion-panels v-if="students && students.length">
      <v-expansion-panel
        v-for="{ student, _id, absences } in this.students"
        :key="_id[0]"
      >
        <v-expansion-panel-header class="text-h6 pa-5" style="color: #00000099">
          {{ student[0].firstname }} {{ student[0].lastname }}
          <span class="body-2 ml-3">({{ student[0].filiere }})</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Du</th>
                  <th class="text-left">Au</th>
                  <th class="text-left">Année</th>
                  <th class="text-left">Statut</th>
                  <th class="text-left">Date de création</th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="absence in absences"
                  :key="absence._id"
                  @click="$router.push(`/absences/${absence._id}`)"
                >
                  <td>
                    {{ format(parseISO(absence.start), "dd.MM.yyyy HH:mm") }}
                  </td>
                  <td>
                    {{ format(parseISO(absence.end), "dd.MM.yyyy HH:mm") }}
                  </td>
                  <td>{{ absence.year }}</td>
                  <td>
                    <v-chip
                      color="grey"
                      text-color="white"
                      v-if="absence.status === 'pending'"
                      small
                      >À traiter</v-chip
                    >
                    <v-chip
                      small
                      color="green lighten-1"
                      text-color="white"
                      v-if="absence.status === 'accepted'"
                      >Justification acceptée</v-chip
                    >
                    <v-chip
                      small
                      color="red lighten-1"
                      text-color="white"
                      v-if="absence.status === 'rejected'"
                      >Justification refusée</v-chip
                    >
                    <v-chip
                      small
                      color="orange lighten-1"
                      text-color="white"
                      v-if="absence.status === 'processing'"
                      >En cours de traitement</v-chip
                    >
                    <v-chip
                      small
                      color="grey"
                      text-color="white"
                      v-if="absence.status === 'cancelled'"
                      >Annulé</v-chip
                    >
                  </td>
                  <td>
                    {{ format(parseISO(absence.createdAt), "dd.MM.yyyy") }}
                  </td>
                  <td class="text-center">
                    <div v-if="absence.upload">
                      <v-icon>mdi-paperclip</v-icon>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" bottom right>
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.visible = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { debounce } from "lodash";
import axios from "axios";
import { parseISO, format } from "date-fns";

export default {
  props: ["search"],
  data: () => ({
    snackbar: { visible: false, text: "", color: "", timeout: 5000 },
    students: [],
    parseISO,
    format,
  }),
  watch: {
    search: debounce(async function (val) {
      try {
        const { data: students } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/admin/students?search=${val}`,
        });
        this.students = students;
      } catch (error) {
        console.log(error);
      }
    }, 300),
  },
  methods: {},
};
</script>