<template>
  <div>
    <v-card outlined class="pa-10 mb-10" v-if="isAdmin && filieres.length > 1">
      <v-card-title>Filtres</v-card-title>
      <v-card-text class="pa-0">
        <v-container>
          <v-row>
            <v-col
              ><v-select
                small-chips
                multiple
                outlined
                dense
                label="Filières"
                :items="filieres"
                v-model="selectedFilieres"
              ></v-select
            ></v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="resetFilters">Réinitialiser les filtres</v-btn>
      </v-card-actions>
    </v-card>
    <v-card outlined class="pa-10">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details
          outlined
          dense
          class="mb-6"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="absences"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        class="elevation-0"
        locale="fr-CH"
        :search="search"
        :footer-props="{
          itemsPerPageOptions: [10, 30, 50],
          itemsPerPageText: 'Élements par page',
        }"
      >
        <!-- eslint-disable-next-line vue/valid-v-slot justification: possible according to vuetify documentation -->
        <template v-slot:body="{ items }">
          <tbody class="text-left">
            <tr
              v-for="item in items"
              :key="item._id"
              @click="$router.push(`/absences/${item._id}`)"
            >
              <td>{{ item.student.lastname }}</td>
              <td>{{ item.student.firstname }}</td>
              <td>{{ item.filiere }}</td>
              <td>{{ item.year }}</td>
              <td>{{ format(parseISO(item.start), "dd.MM.yyyy HH:mm") }}</td>
              <td>{{ format(parseISO(item.end), "dd.MM.yyyy HH:mm") }}</td>
              <td>
                <v-chip
                  color="grey"
                  text-color="white"
                  v-if="item.status === 'pending'"
                  small
                  >À traiter</v-chip
                >
                <v-chip
                  small
                  color="green lighten-1"
                  text-color="white"
                  v-if="item.status === 'accepted'"
                  >Justification acceptée</v-chip
                >
                <v-chip
                  small
                  color="red lighten-1"
                  text-color="white"
                  v-if="item.status === 'rejected'"
                  >Justification refusée</v-chip
                >
                <v-chip
                  small
                  color="orange lighten-1"
                  text-color="white"
                  v-if="item.status === 'processing'"
                  >En cours de traitement</v-chip
                >
                <v-chip
                  small
                  color="grey"
                  text-color="white"
                  v-if="item.status === 'cancelled'"
                  >Annulé</v-chip
                >
              </td>
              <td>
                {{ format(parseISO(item.createdAt), "dd.MM.yyyy HH:mm") }}
              </td>
              <td class="text-center">
                <div v-if="item.upload"><v-icon>mdi-paperclip</v-icon></div>
              </td>
            </tr>
          </tbody>
        </template>
        <!-- eslint-disable-next-line vue/valid-v-slot justification: possible according to vuetify documentation -->
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
          >{{ pageStart }}-{{ pageStop }} de {{ itemsLength }}</template
        >
      </v-data-table>
    </v-card>
  </div>
</template>


<script>
import axios from "axios";
import { debounce } from "lodash";
import { parseISO, format } from "date-fns";

export default {
  props: ["year"],
  async mounted() {
    if (this.$route.path === "/pending-absences") {
      this.statuses = ["pending"];
    }
    if (this.$route.path === "/processing-absences") {
      this.statuses = ["processing"];
    }
    if (this.$route.path === "/history") {
      this.statuses = ["accepted", "rejected", "cancelled"];
    }

    if (this.isAdmin) {
      const { data: filieres } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/filieres`,
      });
      this.filieres = filieres;
    }
  },
  data: () => ({
    loading: false,
    search: "",
    absences: [],
    total: 0,
    options: {},
    statuses: [],
    filieres: [],
    headers: [
      {
        text: "Nom",
        value: "student.lastname",
        sortable: true,
      },
      {
        text: "Prénom",
        value: "student.firstname",
        sortable: true,
      },
      {
        text: "Filière",
        value: "filiere",
        sortable: true,
      },
      {
        text: "Année",
        value: "year",
        sortable: false,
      },
      {
        text: "Du",
        value: "start",
        sortable: true,
      },
      {
        text: "Au",
        value: "end",
        sortable: true,
      },
      {
        text: "Statut",
        value: "status",
        sortable: false,
      },
      {
        text: "Date de création",
        value: "createdAt",
        sortable: true,
      },
      {
        text: "Pièce justificative",
        sortable: false,
        align: "center",
      },
    ],
    parseISO,
    format,
  }),
  methods: {
    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/absences?page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}&itemsPerPage=${itemsPerPage}&status=${this.statuses}&year=${this.year}&filieres=${this.selectedFilieres}&search=${this.search}`,
      });
      this.loading = false;
      let { docs: items } = data;
      const total = data.totalDocs;
      return {
        items,
        total,
      };
    },
    resetFilters() {
      this.selectedFilieres = [];
    },
  },
  watch: {
    search: debounce(async function (value) {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/absences?page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}&itemsPerPage=${itemsPerPage}&search=${value}&status=${this.statuses}&year=${this.year}&filieres=${this.selectedFilieres}`,
      });

      this.loading = false;
      let { docs: items } = data;
      const total = data.totalDocs;
      this.absences = items;
      this.total = total;
    }, 500),
    "$route.path"(after) {
      if (after === "/pending-absences") {
        this.statuses = ["pending"];
      }
      if (after === "/history") {
        this.statuses = ["accepted", "rejected", "cancelled"];
      }
      if (this.$route.path === "/processing-absences") {
        this.statuses = ["processing"];
      }
      this.options.page = null;
      this.options.page = 1;
    },
    year() {
      this.options.page = null;
      this.options.page = 1;
    },
    options: {
      async handler() {
        const data = await this.getDataFromApi();
        this.absences = data.items;
        this.total = data.total;
      },
      deep: true,
    },
  },
  computed: {
    isAdmin() {
      return this.$keycloak.hasResourceRole(
        "admin",
        process.env.VUE_APP_KC_CLIENT_ID
      );
    },
    isManager() {
      return this.$keycloak.hasResourceRole(
        "Responsables de filiere",
        process.env.VUE_APP_KC_CLIENT_ID
      );
    },
    selectedFilieres: {
      get() {
        return this.$route.query.selectedFilieres || "";
      },
      set(value) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            selectedFilieres: value,
          },
        });
        this.options = {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          mustSort: false,
          multiSort: false,
        };
      },
    },
  },
};
</script>