<template>
  <v-card outlined v-if="absence">
    <v-container>
      <v-row>
        <v-col>
          <v-btn
            text
            small
            plain
            class="mt-2"
            @click="$router.back()"
            :ripple="false"
            ><v-icon small class="mr-2">mdi-arrow-left</v-icon>retour</v-btn
          ></v-col
        >
        <v-col class="text-right" v-if="isAdmin">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                plain
                icon
                class="mt-1"
                v-bind="attrs"
                v-on="on"
                :ripple="false"
                @click="saveUpdates(false)"
                ><v-icon>mdi-content-save-outline</v-icon></v-btn
              >
            </template>
            <span>Enregistrer les modifications</span>
          </v-tooltip></v-col
        >
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col class="text-center">
            <div class="text-uppercase headline">Avis d'absence</div>
            <div class="subtitle">
              {{ format(parseISO(absence.createdAt), "dd.MM.yyyy HH:mm") }}
            </div>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col sm="6">
            <v-select
              :items="statuses"
              outlined
              dense
              v-model="absence.status"
              label="Statut"
              :disabled="!isAdmin"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card elevation="0">
              <v-card-title class="subtitle-1">Étudiant</v-card-title>
              <v-card-text>
                <v-text-field
                  outlined
                  dense
                  disabled
                  label="IDISA"
                  :value="absence.student.idisa"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  disabled
                  label="Nom"
                  :value="absence.student.lastname"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  disabled
                  label="Prénom"
                  :value="absence.student.firstname"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  disabled
                  label="E-mail"
                  :value="absence.student.email"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  disabled
                  label="Filière"
                  :value="absence.filiere"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  disabled
                  label="Orientation"
                  :value="absence.orientation || '-'"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  disabled
                  label="Année"
                  :value="absence.year"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  disabled
                  label="Semestre"
                  :value="absence.semester"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  disabled
                  label="Responsable de filière"
                  :value="absence.manager.fullname"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card elevation="0">
              <v-card-title class="subtitle-1">Absence</v-card-title>
              <v-card-text>
                <v-text-field
                  class="my-2"
                  outlined
                  dense
                  disabled
                  label="Type d'annonce"
                  :value="absence.type"
                ></v-text-field>
                <v-row>
                  <v-col class="py-0">
                    <v-menu
                      v-model="startMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="computedStartDate"
                          label="Date de début *"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          :rules="[rules.required]"
                          :disabled="!isAdmin"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        @input="startMenu = false"
                        locale="fr-ch"
                        :max="this.endDate"
                      ></v-date-picker> </v-menu
                  ></v-col>
                  <v-col sm="4" class="py-0"
                    ><v-text-field
                      outlined
                      dense
                      label="Heure de début *"
                      v-model="startTime"
                      v-mask="'##:##'"
                      :rules="[rules.required, rules.timeRange]"
                      :disabled="!isAdmin"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0">
                    <v-menu
                      v-model="endMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="computedEndDate"
                          label="Date de fin *"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          :rules="[rules.required]"
                          :disabled="!isAdmin"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        @input="endMenu = false"
                        locale="fr-ch"
                        :min="this.startDate"
                      ></v-date-picker> </v-menu
                  ></v-col>
                  <v-col sm="4" class="py-0"
                    ><v-text-field
                      outlined
                      dense
                      label="Heure de fin *"
                      v-model="endTime"
                      v-mask="'##:##'"
                      :rules="[rules.required, rules.timeRange]"
                      :disabled="!isAdmin"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-textarea
                  outlined
                  dense
                  auto-grow
                  label="Motif *"
                  v-model="absence.reason"
                  v-if="isAdmin || isManager"
                  class="my-2"
                  :disabled="!isAdmin"
                ></v-textarea>

                <v-container class="pa-0">
                  <v-row
                    ><v-col sm="12">
                      <div
                        class="subtitle-1 black--text"
                        v-if="isAdmin || isManager"
                      >
                        Document étudiant
                      </div>
                      <div v-if="isAdmin || isManager">
                        <div v-if="absence.upload">
                          <v-chip
                            color="grey darken-1"
                            label
                            text-color="white"
                            @click="downloadDocument(absence.upload)"
                            style="width: 100%"
                          >
                            <v-icon left> mdi-file </v-icon>
                            {{ absence.upload.substr(22) }}
                          </v-chip>
                        </div>
                        <div v-else>Aucun document fourni.</div>
                      </div> </v-col
                    ><v-col sm="12"
                      ><div
                        class="subtitle-1 black--text"
                        v-if="isAdmin || isManager"
                      >
                        Document Secrétariat
                      </div>
                      <div v-if="isAdmin || isManager">
                        <div v-if="absence.adminUpload">
                          <v-chip
                            color="grey darken-1"
                            label
                            text-color="white"
                            @click="downloadDocument(absence.adminUpload)"
                            :close="isAdmin"
                            close-icon="mdi-delete"
                            @click:close="removeDocument"
                            style="width: 100%"
                          >
                            <v-icon left> mdi-file </v-icon>
                            {{ absence.adminUpload.substr(22) }}
                          </v-chip>
                        </div>
                        <v-file-input
                          v-else
                          outlined
                          dense
                          label="Pièce justificative"
                          @change="handleUpload"
                          :loading="uploading"
                          :disabled="!isAdmin"
                        >
                        </v-file-input></div></v-col
                  ></v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row align="center" justify="center" v-if="isAdmin || isManager">
          <v-col sm="6">
            <div class="subtitle-1 black--text">Commentaires</div></v-col
          >
        </v-row>
        <v-row align="center" justify="center" v-if="isAdmin || isManager">
          <v-col sm="6">
            <v-card
              outlined
              shaped
              class="mb-5"
              v-for="comment in absence.comments"
              :key="comment._id"
            >
              <v-card-title class="body-1">{{ comment.author }}</v-card-title>
              <v-card-subtitle>
                {{
                  format(parseISO(comment.date), "dd.MM.yyyy à HH:mm")
                }}</v-card-subtitle
              >
              <v-card-text>{{ comment.content }}</v-card-text>
            </v-card>
            <v-textarea
              outlined
              dense
              label="Commentaire"
              v-model="comment"
              v-if="isAdmin"
            ></v-textarea
            ><v-btn outlined dense block @click="addComment" v-if="isAdmin"
              >Ajouter un commentaire</v-btn
            ></v-col
          >
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import { format } from "date-fns-tz";
import {
  parseISO,
  formatISO,
  parse,
  setMinutes,
  setHours,
  isWithinInterval,
} from "date-fns";
import fileDownload from "js-file-download";
import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  async mounted() {
    try {
      const { data: absence } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/absences/${this.$route.params._id}`,
      });
      this.absence = absence;
      this.startDate = format(parseISO(absence.start), "yyyy-MM-dd");
      this.startTime = format(parseISO(absence.start), "HH:mm");
      this.endDate = format(parseISO(absence.end), "yyyy-MM-dd");
      this.endTime = format(parseISO(absence.end), "HH:mm");
    } catch (error) {
      console.log(error);
    }
  },
  props: ["openSnackbar"],
  data: () => ({
    rules: {
      required: (v) => !!v || "Ce champ est requis.",
      timeRange: (v) => {
        if (v.length < 5) {
          return "Format invalide";
        }
        if (v.length === 5) {
          const parsed = parse(v, "HH:mm", new Date());
          const start = setMinutes(setHours(new Date(), 0), 0);
          const end = setMinutes(setHours(new Date(), 23), 59);
          if (parsed.toString() === "Invalid Date") {
            return "Format invalide";
          }
          if (!isWithinInterval(parsed, { start, end })) {
            return "Compris entre 00:01 et 23:59";
          }
        }
        return true;
      },
    },
    absence: undefined,
    uploading: false,
    parseISO,
    format,
    comment: "",
    startMenu: false,
    endMenu: false,
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    statuses: [
      { text: "À traiter", value: "pending" },
      { text: "En cours de traitement", value: "processing" },
      { text: "Justification acceptée", value: "accepted" },
      { text: "Justification refusée", value: "rejected" },
      { text: "Annulé", value: "cancelled" },
    ],
  }),
  methods: {
    async addComment() {
      this.absence.comments.push({
        date: formatISO(new Date()),
        author: this.$keycloak.fullName,
        content: this.comment,
      });
      this.comment = "";
      await this.saveUpdates(true);
    },
    async saveUpdates(isComment) {
      await axios({
        method: "patch",
        url: `${process.env.VUE_APP_API_URI}/admin/absences/${this.absence._id}?isComment=${isComment}`,
        data: {
          status: this.absence.status,
          adminUpload: this.absence.adminUpload,
          comments: this.absence.comments,
          reason: this.absence.reason,
          start: this.absence.start,
          end: this.absence.end,
        },
      });
      this.openSnackbar({
        visible: true,
        text: "Demande mise à jour",
        color: "success",
      });
    },
    async downloadDocument(filename) {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/files/${filename}`,
        responseType: "arraybuffer",
      });
      fileDownload(data, filename.substr(22));
    },
    async handleUpload(file) {
      if (file) {
        this.uploading = true;
        try {
          const formData = new FormData();
          formData.append("file", file);
          const { data } = await axios({
            method: "post",
            url: `${process.env.VUE_APP_API_URI}/uploads`,
            data: formData,
            headers: {
              "content-Type": "multipart/form-data",
              "x-api-key": process.env.VUE_APP_API_KEY,
            },
          });
          this.absence.adminUpload = data.filename;
          this.uploading = false;
        } catch (e) {
          this.text = "Erreur de chargement du fichier";
          this.color = "error";
          this.snackbar = true;
          this.uploading = false;
        }
      }
    },
    removeDocument() {
      this.absence.adminUpload = "";
    },
    parseAndFormatISO(date, time) {
      return format(
        parse(`${date} ${time}`, "yyyy-MM-dd HH:mm", new Date()),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
        { timeZone: "Europe/Zurich" }
      );
    },
    computeISODate(type) {
      this.absence[type] = "";
      if (
        this[`${type}Date`] &&
        this[`${type}Time`] &&
        this[`${type}Time`].length === 5
      ) {
        this.absence[type] = this.parseAndFormatISO(
          this[`${type}Date`],
          this[`${type}Time`]
        );
      }
    },
  },
  computed: {
    isAdmin() {
      return this.$keycloak.hasResourceRole(
        "admin",
        process.env.VUE_APP_KC_CLIENT_ID
      );
    },
    isManager() {
      return this.$keycloak.hasResourceRole(
        "Responsables de filiere",
        process.env.VUE_APP_KC_CLIENT_ID
      ) || this.$keycloak.hasResourceRole(
        "Doyens HEIG-VD",
        process.env.VUE_APP_KC_CLIENT_ID
      );
    },
    startDateTime() {
      return `${this.startDate}|${this.startTime}`;
    },
    endDateTime() {
      return `${this.endDate}|${this.endTime}`;
    },
    computedStartDate() {
      return this.startDate
        ? format(parseISO(this.startDate), "dd.MM.yyyy")
        : "";
    },
    computedEndDate() {
      return this.endDate ? format(parseISO(this.endDate), "dd.MM.yyyy") : "";
    },
  },
  watch: {
    startDateTime() {
      this.computeISODate("start");
    },
    endDateTime() {
      this.computeISODate("end");
    },
  },
};
</script>

<style>
.v-input--radio-group__input {
  justify-content: center;
}
</style>