import Vue from "vue";
import VueRouter from "vue-router";
import List from "@/views/List.vue";
import Details from "@/views/Details.vue";
import Students from "@/views/Students.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/pending-absences",
  },
  {
    path: "/pending-absences",
    name: "Demandes en attente",
    component: List,
  },
  {
    path: "/processing-absences",
    name: "Demandes en cours",
    component: List,
  },
  {
    path: "/history",
    name: "Historique",
    component: List,
  },
  {
    path: "/absences/:_id",
    name: "Details",
    component: Details,
  },
  {
    path: "/students",
    name: "Students",
    component: Students,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
