<template>
  <v-container>
    <v-row class="mb-0">
      <v-col>
        <absence-table v-bind="$props" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AbsenceTable from "@/components/AbsenceTable";
export default {
  name: "App",
  props: ["year", "years"],
  components: { AbsenceTable },
};
</script>

<style>
.v-speed-dial {
  position: absolute;
}
.v-btn--floating {
  position: relative;
}
</style>